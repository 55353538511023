import React, {
   Dispatch,
   useContext,
   createContext,
   useState,
   SetStateAction
} from "react";
import { HttpStatusCode } from "axios";
import { IAllProcessServerUser, IProcessServerCaseInfo, IProcessServerCaseInfoItem } from "interfaces/process-server.interface";
import ProcessServerService from "services/process-server.service";
import { AOSStatusEnum } from "utils/enum";


export type ProcessServerContextType = {
   showSpinner: boolean;
   setShowSpinner: Dispatch<SetStateAction<boolean>>;
   processServerCases: IProcessServerCaseInfo;
   setProcessServerCases: Dispatch<SetStateAction<IProcessServerCaseInfo>>;
   getProcessServerCases: (currentPage: number, pageSize: number, searchParam?: string, serverName?: string, serviceMethod?: string, dateRange?: string) => void;
   selectedProcessServerId: string[];
   setSelectedProcessServerId: Dispatch<SetStateAction<string[]>>;
   filteredRecords: IProcessServerCaseInfoItem[],
   setFilteredRecords: Dispatch<SetStateAction<IProcessServerCaseInfoItem[]>>;
   selectedFilteredProcessServerId: string[];
   setSelectedFilteredProcessServerId: Dispatch<SetStateAction<string[]>>;
   bulkRecords: IProcessServerCaseInfoItem[];
   setBulkRecords: Dispatch<SetStateAction<IProcessServerCaseInfoItem[]>>;
   allProcessServerUsers: IAllProcessServerUser[];
   setAllProcessServerUsers: Dispatch<SetStateAction<IAllProcessServerUser[]>>;
   getAllProcessServerUsers: () => void;
};

const initialProcessServerContextValue: ProcessServerContextType = {
   showSpinner: false,
   setShowSpinner: () => { },
   processServerCases: {
      items: [
         {
            id: "",
            dispoId: "",
            caseNumber: "",
            unitNumber: "",
            streetNumber: "",
            streetName: "",
            personServed: "",
            height: "",
            weight: "",
            age: "",
            serviceMethod: "",
            serviceNotes: "",
            serviceDate: "",
            dateScanned: "",
            // longitude: "",
            // latitude: "",
            locationCoord: "",
            comments: "",
            tenantnames: "",
            propertyName: "",
            //  defendantName: "",
            // house: "",
            // tack: false,
            // personal: false,
            // notorious: false,
            // nonEst: false,
            // informationOnly: false,
            companyName: "",
            serverName: "",
            status: AOSStatusEnum.NeedToSend,
            serverEmail: "",
            serverExists: false,
            filingType: ""
         },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 0,
      totalPages: 1,
      searchParam: "",
      serverName: "",
      serviceMethod: "",
      dateRange: ""
   },
   setProcessServerCases: () => { },
   getProcessServerCases: () => { },
   selectedProcessServerId: [],
   setSelectedProcessServerId: () => { },
   filteredRecords: [],
   setFilteredRecords: () => { },
   selectedFilteredProcessServerId: [],
   setSelectedFilteredProcessServerId: () => { },
   bulkRecords: [],
   setBulkRecords: () => { },
   allProcessServerUsers: [],
   setAllProcessServerUsers: () => { },
   getAllProcessServerUsers: () => { },
};

export const ProcessServerContext = createContext<ProcessServerContextType>(initialProcessServerContextValue);

export const ProcessServerProvider: React.FC<{ children: any }> = ({ children }) => {
   const [showSpinner, setShowSpinner] = useState<boolean>(false);
   const [processServerCases, setProcessServerCases] = useState<IProcessServerCaseInfo>(
      initialProcessServerContextValue.processServerCases
   );
   const [bulkRecords, setBulkRecords] = useState<IProcessServerCaseInfoItem[]>([]);
   const [selectedProcessServerId, setSelectedProcessServerId] = useState<string[]>(
      initialProcessServerContextValue.selectedProcessServerId
   );
   const [filteredRecords, setFilteredRecords] = useState<IProcessServerCaseInfoItem[]>([]);
   const [selectedFilteredProcessServerId, setSelectedFilteredProcessServerId] = useState<string[]>(
      initialProcessServerContextValue.selectedFilteredProcessServerId
   );
   const [allProcessServerUsers, setAllProcessServerUsers] = useState<IAllProcessServerUser[]>(
      initialProcessServerContextValue.allProcessServerUsers
   );

   const getProcessServerCases = async (
      currentPage: number,
      pageSize: number,
      searchParam?: string,
      serverName?: string,
      serviceMethod?: string,
      dateRange?: string
   ) => {
      try {

         setShowSpinner(true);
         // get All cases
         const apiResponse = await ProcessServerService.getProcessServerCases(
            currentPage,
            pageSize,
            searchParam,
            serverName,
            serviceMethod,
            dateRange
         );
         if (apiResponse.status === HttpStatusCode.Ok) {
            setProcessServerCases((prevAllCases) => ({
               ...prevAllCases,
               items: apiResponse.data.items,
               currentPage: apiResponse.data.currentPage,
               totalCount: apiResponse.data.totalCount,
               totalPages: apiResponse.data.totalPages,
               pageSize: apiResponse.data.pageSize,
               ...(searchParam ? { searchParam: searchParam } : {}),
            }));
         }
      } finally {
         setShowSpinner(false);
      }
   };

   const getAllProcessServerUsers = async () => {
      try {
         const response = await ProcessServerService.getAllProcessServerUsers();
         if (response.status === HttpStatusCode.Ok) {
            setAllProcessServerUsers(response.data);
         }

      } catch (error) {
         console.log("🚀 ~ getProcessServerUsers ~ error:", error);
      } finally {

      }
   };

   return (
      <ProcessServerContext.Provider
         value={{
            showSpinner,
            setShowSpinner,
            processServerCases,
            setProcessServerCases,
            getProcessServerCases,
            selectedProcessServerId,
            setSelectedProcessServerId,
            filteredRecords,
            setFilteredRecords,
            selectedFilteredProcessServerId,
            setSelectedFilteredProcessServerId,
            bulkRecords,
            setBulkRecords,
            allProcessServerUsers,
            setAllProcessServerUsers,
            getAllProcessServerUsers
         }}
      >
         {children}
      </ProcessServerContext.Provider>
   );
}

export const useProcessServerContext = (): ProcessServerContextType => {
   // Get the context value using useContext
   const context = useContext(ProcessServerContext);
   // If the context is not found, throw an error
   if (!context) {
      throw new Error(
         "useProcessServerContext must be used within a ProcessServerProvider"
      );
   }

   return context;
};