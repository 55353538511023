import React, { Fragment, useState } from "react";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FaEdit, FaEnvelope, FaExclamationTriangle, FaInfoCircle, FaRedo, FaSyncAlt, FaTrash, FaFilePdf } from "react-icons/fa";
import { IEmailQueueButton } from "interfaces/email-queue.interface";
import Button from "components/common/button/Button";
import Modal from "components/common/popup/PopUp";
import { useEmailQueueContext } from "../EmailQueueContext";
import EmailQueueService from "services/email-queue.service";
import EmailQueueBulkEdit from "./EmailQueueActions/EmailQueue_BulkEdit";
import ServerEmailLogBulkEdit from "./EmailQueueActions/ServerEmailLog_BulkEdit";
import MailManagementBulkEdit from "./EmailQueueActions/MailManagement_BulkEdit";
import EvictionQueuesService from "services/eviction-queue.service";

type EmailQueueButtonProps = {
   buttons: IEmailQueueButton[];
   activeTab: string;
};

const classNames = (...classes: string[]) => {
   return classes.filter(Boolean).join(" ");
};

const EmailQueueButtons = (props: EmailQueueButtonProps) => {
   const [sendEmailConfirmation, setSendEmailConfirmation] = useState<boolean>(false);
   const [showInfo, setShowInfo] = useState<boolean>(false);
   const [bulkEditPopUp, setBulkEditPopUp] = useState<boolean>(false);
   const [bulkEditServerLogPopUp, setBulkEditServerLogPopUp] = useState<boolean>(false);
   const [bulkEditMailManagementPopUp, setBulkEditMailManagementPopUp] = useState<boolean>(false);
   const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
   const [errorMessage, setErrorMsg] = useState<string>("");
   const [
      showErrorMessageWhenNoRowIsSelected,
      setShowErrorMessageWhenNoRowIsSelectedState,
   ] = useState<boolean>(false);
   const setShowErrorMessageWhenNoRowIsSelected = (show: boolean, customMessage?: string) => {
      setErrorMsg(customMessage || "Please select at least 1 record.");
      setShowErrorMessageWhenNoRowIsSelectedState(show);
   };
   const handleModalClose = () => {
      setSendEmailConfirmation(false);
   };
   const {
      selectedEmailQueueIds,
      selectedServerLogIds,
      getEmailQueues,
      setShowSpinner,
      setEmailQueues,
      setServerEmailLogs,
      allCounties,
      bulkRecords,
      emailQueues
   } = useEmailQueueContext();
   const [envelopIds, setEnvelopIds] = useState<string[]>();
   const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
   const [showSyncPopup, setShowSyncPopup] = useState<boolean>(false);

   const handleSendEmail = async () => {
      setSendEmailConfirmation(false);
      setShowSpinner(true);
      const response = await EmailQueueService.sendEmailToServer(selectedEmailQueueIds,emailQueues.searchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt,emailQueues.status,emailQueues.taskStatus);
      if (response.status === HttpStatusCode.Ok) {

         if (response.data.isSuccess)
            toast.success("Email sent successfully to the server and filer.");
         else {
            setErrorMsg(response.data.message);
            setShowInfo(true);
         }
         // toast.warning(response.data.message);

         getEmailQueues(1, 100,emailQueues.searchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt,emailQueues.status,emailQueues.taskStatus);
      }
      setShowSpinner(false);
   };

   const resetSelectedRows = () => {
      setEmailQueues((prev) => {
         return {
            ...prev,
            items: prev.items.map((item) => ({
               ...item,
               isChecked: false,
            })),
         };
      });
      setServerEmailLogs((prev) => {
         return {
            ...prev,
            items: prev.items.map((item) => ({
               ...item,
               isChecked: false,
            })),
         };
      });
   };

   const handleDeleted = async () => {
      setDeleteConfirmation(false);
      setShowSpinner(true);
      const response = await EmailQueueService.deleteCases(selectedEmailQueueIds);
      if (response.status === HttpStatusCode.Ok) {
         if (response.data.isSuccess)
            toast.success(response.data.message);
         else {
            setErrorMsg(response.data.message);
         }
         getEmailQueues(1, 100, "");
      }
      setShowSpinner(false);
   };

   const handleClick = (button: IEmailQueueButton) => {
      // Switch based on the button type or any other property that uniquely identifies the button
      switch (button.title) {
         case "Send Email":
            setSendEmailConfirmation(true);
            break;
         case "Manual Crawl":
            handleManualCrawling();
            break;
         case "Edit":
            if (props.activeTab === "Process Server Eviction") {
               // Check if selectedServerLogIds is empty for Process Server Eviction tab
               if (selectedServerLogIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditServerLogPopUp(true);
               }
            } else if (props.activeTab === "Mail Management") {
               // Check if selectedEmailQueueIds is empty for Mail Management tab
               if (selectedEmailQueueIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditMailManagementPopUp(true);
               }
            } else {
               // General case: check selectedEmailQueueIds for other tabs
               if (selectedEmailQueueIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditPopUp(true);
               }
            }
            break;
         case "Delete":
            if (selectedEmailQueueIds.length === 0) {
               setShowErrorMessageWhenNoRowIsSelected(
                  true,
                  "Please select at least one record."
               );
            } else {
               setShowErrorMessageWhenNoRowIsSelected(false);
               setDeleteConfirmation(true);
            }
            break;
            case "Sync":
            if (selectedEmailQueueIds.length === 0) {
               setShowErrorMessageWhenNoRowIsSelected(
                  true,
                  "Please select at least one record."
               );
            } else {
               const records = bulkRecords.filter(
                  (item) => selectedEmailQueueIds.includes(item.id || "") && item.status !== "Accepted"
               );

               const envelopeIds = records
                  .map((x) => x.evictionEnvelopeID)
                  .filter((id): id is string => id !== undefined && id !== null);

                  const taskIds = records
                     .filter((x) => Boolean(x.evictionEnvelopeID))
                     .map((x) => x.taskId)
                     .filter((taskId): taskId is string => Boolean(taskId));
               
               if (envelopeIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Envelope ID is not present for the selected case"
                  );
               } else {
                  setSelectedTaskIds(taskIds);
                  setEnvelopIds(envelopeIds);
                  setShowSyncPopup(true);
                  setShowErrorMessageWhenNoRowIsSelected(false);
               }
            }
            break;
         default:
            // Handle default case or unknown button types
            console.log(`Unknown button type: ${button.icon}`);
      }
   };

   const handleManualCrawling = async () => {
      toast.info("Manual Crawling process has started.");
      const response = await EmailQueueService.runManualCrawling();
      if (response.status === HttpStatusCode.Ok) {
         // toast.success("Crawling Service started Successfully.");
         toast.success(response.data.message);
      } else {
         toast.error("Failed to Crawl Data.");
      }
   };

   const syncCasesStatus = async () => {
      try {
         setShowSyncPopup(false)
         const response = await EvictionQueuesService.syncManualCaseStatus(selectedTaskIds);
         if (response.status === HttpStatusCode.Ok) {
            setEnvelopIds([]);
            setShowSyncPopup(false);
            getEmailQueues(1, 100, "");
            toast.success(response.data.message);
         }
      } catch (error) {
         console.error(error);
      } finally {
         
      }
   };

   const handleDownloadDocument = async () => {
      setShowErrorMessageWhenNoRowIsSelected(false);
      await getDocumentLink();
   };

   const getDocumentLink = async () => {
      try {
         const apiResponse = await EmailQueueService.getManualCasesDocuments(selectedEmailQueueIds);
         if (apiResponse.status === HttpStatusCode.Ok) {
            const pdfUrls = apiResponse.data;
            // Ensure the data is an array of objects containing pdfUrls
            if (Array.isArray(pdfUrls) && pdfUrls.length > 0) {
               toast.info("File downloading has been started");
               // Initialize JSZip
               const zip = new JSZip();
               const existingFileNames = new Set();

               // Fetch all PDFs and add them to the zip
               for (const url of pdfUrls) {
                  try {
                      const pdfBlob = await fetchPDFBlob(url);
                      const pathParts = url.split('/');
                      const uniquePart = pathParts.slice(-2).join('_');
                      const fileName = url.substring(url.lastIndexOf("/") + 1);

                      // Ensure unique file names in the zip
                      const zipFileName = existingFileNames.has(fileName) ? uniquePart : fileName;
                      existingFileNames.add(zipFileName);

                      zip.file(zipFileName, pdfBlob);
                  } catch (error) {
                      console.error(`Failed to fetch and add PDF from ${url}:`, error);
                  }
              }

               // Generate the zip file and trigger download
               const zipBlob = await zip.generateAsync({ type: "blob" });
               saveAs(zipBlob, "C2C_PDF_Export.zip");
               toast.success("File has been successfully downloaded");
            } else {
               toast.error("Not found any file for the selected records");
            }
         }
      } catch (error) {
         console.error('Error fetching and downloading PDFs:', error);
         toast.error("Error fetching and downloading PDFs");
      } finally {
         setShowSpinner(false);
      }
   };

   const fetchPDFBlob = async (pdfUrl: string) => {
      try {
         const response = await fetch(pdfUrl, {
            headers: {
               "Content-Type": "application/pdf",
            },
         });

         if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
         }

         return await response.blob();
      } catch (error) {
         console.error("Error fetching PDF:", error);
         throw error;
      }
   };

   return (
      <>
         {props.buttons.map((item: IEmailQueueButton, index: number) => {
            let iconComponent;
            switch (item.icon) {
               case "FaFileExcel":
                  iconComponent = (
                     <FaEnvelope className="fa-solid fa-plus mr-0.5 text-xs" />
                  );
                  break;
               case "FaEdit":
                  iconComponent = (
                     <FaEdit className="fa-solid fa-plus  mr-0.5 text-xs " />
                  );
                  break;
               case "FaTrash":
                  iconComponent = (
                     <FaTrash className="fa-solid fa-plus  mr-0.5 text-xs " />
                  );
                  break;
               case "FaRedo":
                  iconComponent = (
                     <FaRedo className="fa-solid fa-plus  mr-1 text-xs" />
                  );
                  break;
               case "FaSyncAlt":
                  iconComponent = (
                     <FaSyncAlt className="fa-solid fa-plus  mr-1 text-xs" />
                  );
                  break;
               default:
                  // Provide a default case or handle unknown icon types
                  iconComponent = <FaEnvelope className="text-[13px] mr-0.5" />;
            };

            return (
               <Button
                  title={item.title}
                  classes={(props.activeTab === "Process Server Eviction" ||
                     props.activeTab === "Mail Management") &&
                     (item.title === "Send Email" ||
                        item.title === "Manual Crawl" ||
                        item.title === "Sync" ||
                        item.title === "Delete")
                     ? "hidden"
                     : item.classes
                  }
                  type={"button"}
                  isRounded={false}
                  icon={iconComponent}
                  key={index}
                  handleClick={() => handleClick(item)}
               ></Button>
            );
         })}

         {props.activeTab === "Mail Management" && (
            <>
               <Menu
                  as="div"
                  className="relative inline-block text-left z-[11] mb-1 ml-1 md:ml-1.5"
               >
                  <div>
                     <Menu.Button className="inline-flex w-full justify-center gap-x-1 rounded-md bg-white px-2.5 py-1.5 text-[11px] md:text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Download
                        <ChevronDownIcon
                           className="-mr-0.5 h-4 w-4 text-gray-400"
                           aria-hidden="true"
                        />
                     </Menu.Button>
                  </div>
                  <Transition
                     as={Fragment}
                     enter="transition ease-out duration-100"
                     enterFrom="transform opacity-0 scale-95"
                     enterTo="transform opacity-100 scale-100"
                     leave="transition ease-in duration-75"
                     leaveFrom="transform opacity-100 scale-100"
                     leaveTo="transform opacity-0 scale-95"
                  >
                     <Menu.Items className="dropdown-menu absolute left-0 md:left-auto md:right-0 mt-2 w-60 md:w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1 text-nowrap">
                              <Menu.Item>
                                 {({ active }) => (
                                    <a
                                       className={classNames(
                                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                          "block px-3.5 py-1.5 text-[11px] md:text-xs cursor-pointer flex items-center font-semibold"
                                       )}
                                       onClick={() => handleDownloadDocument()}
                                    >
                                       <FaFilePdf className="fa-solid fa-plus mr-1 text-[11px] md:text-xs" />{" "}
                                       Download Documents
                                    </a>
                                 )}
                              </Menu.Item>
                        </div>
                     </Menu.Items>
                  </Transition>
               </Menu>
            </>
         )}

         {(
            <Modal
               showModal={showInfo}
               onClose={() => {
                  setShowInfo(false);
               }}
               width="max-w-md"
            >
               <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 rounded-md">
                  <div className="text-center py-8">
                     <div className="mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-blue-100  sm:h-16 sm:w-16 mx-auto">
                        <FaInfoCircle className="h-6 w-6 text-blue-600" />
                     </div>
                     <div className="mt-2.5 text-center ">
                        <p className="text-xs text-gray-500 text-center font-medium text-gray-600 text-md">
                           {errorMessage}
                        </p>
                     </div>
                  </div>
               </div>
            </Modal>
         )}
         {sendEmailConfirmation && (
            <div>
               <Modal
                  showModal={sendEmailConfirmation}
                  onClose={handleModalClose}
                  width="max-w-md"
               >
                  <div id="fullPageContent">
                     <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                        <div className="text-center pr-4 sm:text-left">
                           <h3
                              className="text-sm font-semibold leading-5 text-gray-900"
                              id="modal-title"
                           >
                              Are you sure you want to send email to the process server(s)?
                           </h3>
                        </div>
                     </div>
                     <div className="flex justify-end m-2">
                        <Button
                           type="button"
                           isRounded={false}
                           title="No"
                           handleClick={handleModalClose}
                           classes="text-[11px] md:text-xs bg-white	inline-flex justify-center items-center rounded-md text-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 shadow-lg "
                        ></Button>
                        <Button
                           handleClick={handleSendEmail}
                           title="Yes"
                           isRounded={false}
                           type={"button"}
                           classes="text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
                        ></Button>
                     </div>
                  </div>
               </Modal>
            </div>
         )}
         {bulkEditPopUp && (
            <>
               <EmailQueueBulkEdit
                  showEmailQueuePopup={bulkEditPopUp}
                  handleClose={() => {
                     setBulkEditPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditPopUp(false);
                  }}
                  counties={allCounties.map(c => c.countyName.toLowerCase())}
               />
            </>
         )}
         {bulkEditServerLogPopUp && (
            <>
               <ServerEmailLogBulkEdit
                  showPopup={bulkEditServerLogPopUp}
                  handleClose={() => {
                     setBulkEditServerLogPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditServerLogPopUp(false);
                  }}
               />
            </>
         )}
         {bulkEditMailManagementPopUp && (
            <>
               <MailManagementBulkEdit
                  showPopup={bulkEditMailManagementPopUp}
                  handleClose={() => {
                     setBulkEditMailManagementPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditMailManagementPopUp(false);
                  }}
               />
            </>
         )}
         {showErrorMessageWhenNoRowIsSelected && (
            <Modal
               showModal={showErrorMessageWhenNoRowIsSelected}
               onClose={() => {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  resetSelectedRows();
               }}
               width="max-w-md"
            >
               <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                  <div className="text-center py-8">
                     <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-auto">
                        <FaExclamationTriangle className="h-5 w-5 text-red-600" />
                     </div>
                     <div className="mt-2.5 text-center ">
                        <p className="text-xs text-gray-500 text-center font-medium text-gray-600 text-md">
                           {errorMessage}
                        </p>
                     </div>
                  </div>
               </div>
            </Modal>
         )}
         {deleteConfirmation && (
            <div>
               <Modal
                  showModal={deleteConfirmation}
                  onClose={() => setDeleteConfirmation(false)}
                  width="max-w-md"
               >
                  <div id="fullPageContent">
                     <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                        <div className="text-center pr-4 sm:text-left">
                           <h3
                              className="text-sm font-semibold leading-5 text-gray-900"
                              id="modal-title"
                           >
                              Are you sure you want to delete selected case(s)?
                           </h3>
                        </div>
                     </div>
                     <div className="flex justify-end m-2">
                        <Button
                           type="button"
                           isRounded={false}
                           title="No"
                           handleClick={() => setDeleteConfirmation(false)}
                           classes="text-[11px] md:text-xs bg-white	inline-flex justify-center items-center rounded-md text-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 shadow-lg "
                        ></Button>
                        <Button
                           handleClick={handleDeleted}
                           title="Yes"
                           isRounded={false}
                           type={"button"}
                           classes="text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
                        ></Button>
                     </div>
                  </div>
               </Modal>
            </div>
         )}
         {showSyncPopup && (
            <Modal showModal={showSyncPopup} onClose={() => setShowSyncPopup(false)} width="max-w-xl">
               <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 rounded-md">
                  <div className="sm:flex sm:items-start">
                     <div className="text-center sm:text-left">
                        <h4
                           className="leading-5 text-gray-900 text-[15.2px] mb-1.5 font-medium"
                           id="modal-title"
                        >
                           Are you sure you want to sync status for the following case(s)?
                        </h4>
                     </div>
                  </div>
                  <div>
                     <div className="md:grid-cols-2 gap-2.5 sm:gap-3.5 mb-2.5 mt-2">
                        <div className="relative p-2.5 shadow-md rounded border">
                           <p className="text-xs">
                              {envelopIds && envelopIds.length > 0 ? envelopIds.join(",") : "No envelope IDs are available for syncing the status."}
                           </p>
                        </div>
                     </div>
                     <div className="mt-1.5 md:mt-0 py-2.5 flex justify-end items-center">
                        <Button
                           type="button"
                           isRounded={false}
                           title="Cancel"
                           handleClick={() => setShowSyncPopup(false)}
                           classes="text-xs bg-white inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-[#f5f8fb] hover:ring-slate-900/15 shadow-lg"
                        ></Button>
                        <Button
                           title="Ok"
                           type="button"
                           isRounded={false}
                           handleClick={syncCasesStatus}
                           disabled={(envelopIds && envelopIds.length === 0)}
                           classes="py-2 md:py-2.5 px-4 inline-flex justify-center items-center gap-x-1.5 text-xs font-semibold rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        ></Button>
                     </div>
                  </div>
               </div>
            </Modal>
         )}
      </>
   );
};

export default EmailQueueButtons;