import React, { useEffect, useRef, useState } from 'react';
import HighlightedText from 'components/common/highlightedText/HighlightedText';

 export const formattedCell = (value: any) => (
    <span>{value !== null ? value : ""}</span>
 );

 export const formatAttorneyCell = (record: any) => (
    <span>
       {record.attorneyName && <>{formatNoWrapSpan(record.attorneyName)}<br/></>}
       {record.attorneyBarNo && <>Bar#: {formatNoWrapSpan(record.attorneyBarNo)}<br/></>}
       {record.attorneyEmail && <>{formatNoWrapSpan(record.attorneyEmail)}<br/></>}
    </span>
 );
    
 export const formatTenantName = (value: any, index: number) => (
       `${value.firstName ?? ""} ${value.middleName ?? ""} ${value.lastName ?? ""}`
       .trim()
       .replace(/\s+/g, ' ')
 );
 export const formatTenantUnitCell = (value: string) => (
    <span style={{fontWeight: '600'}}>
       {value}
    </span>
 );
 export const formatTenantNamesCell = (values: any[], andallothers: string) => (
    <span style={{ fontWeight: '600' }}>
       {values.map((value, index) => (
          <>
             <span key={index} className="whitespace-nowrap">
                {formatTenantName(value, index)}
             </span>
             <br/>
             </>
          ))}
          {(andallothers) && (
             <span className="whitespace-nowrap">
                {andallothers}
             </span>
          )}
    </span>
 );
 export const formatNoWrapSpan = (value: string) => (
    <span className="whitespace-nowrap">
       {value}
    </span>
 );
 export const formatCityStateZip = (city: string, state: string, zip: string) => (
    ((city ?? "") + ", " + (state ?? "") + " " + (zip ?? ""))
 );
 export const formatTenantAddressCell = (record: any) => (
    <span style={{ fontWeight: '600' }}>
       {record.tenantAddress && <>{formatNoWrapSpan(record.tenantAddress)}<br/></>}
       {formatNoWrapSpan(formatCityStateZip(record.tenantCity, record.tenantState, record.tenantZip))}<br/>
    </span>
 );
 export const formatPropertyNameAddressCell = (record: any) => (
    <span>
       {record.ownerName && <>{formatNoWrapSpan(record.ownerName)}<br/></>}
       {record.propertyName && <>{formatNoWrapSpan(record.propertyName)}<br/></>}
       {record.propertyAddress && <>{formatNoWrapSpan(record.propertyAddress)}<br/></>}
       {(record.propertyCity || record.propertyState || record.propertyZip) && (
          <>
          <span className="whitespace-nowrap">
             {record.propertyCity ?? ""}, {record.propertyState ?? ""} {record.propertyZip ?? ""}
          </span>
          <br/>
          </>
       )}
       {(record.propertyPhone || record.propertyEMail) && (
          <>
             {record.propertyPhone ?? ""} {record.propertyEMail ?? ""}
          </>
       )}
    </span>
 );

export const renderHighlightedCell = (value: any, searchParam: any) => (
   <HighlightedText text={value as string ?? ''} query={searchParam ?? '2'} />
);

export const formatFilerBusinessInfoCell = (record: any) => (
   <span>
      {record.filerBusinessName && <>{formatNoWrapSpan(record.filerBusinessName)}<br/></>}
      {record.filerPhone && <>{formatNoWrapSpan(record.filerPhone)}<br/></>}
      {record.filerEmail && <>{formatNoWrapSpan(record.filerEmail)}<br/></>}
   </span>
);
