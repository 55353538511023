import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import * as yup from "yup";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { FaCopy, FaEdit, FaEye, FaTimes } from "react-icons/fa";
import { useAuth } from "context/AuthContext";
import { useEmailQueueContext } from "../EmailQueueContext";
import Spinner from "components/common/spinner/Spinner";
import Grid from "components/common/grid/GridWithToolTip";
import Pagination from "components/common/pagination/Pagination";
import GridCheckbox from "components/formik/GridCheckBox";
import Modal from "components/common/popup/PopUp";
import FormikControl from "components/formik/FormikControl";
import Button from "components/common/button/Button";
import IconButton from "components/common/button/IconButton";
import HighlightedText from "components/common/highlightedText/HighlightedText";
import { IGridHeader } from "interfaces/grid-interface";
import { IEditEmailQueueItem, IEmailQueueItem } from "interfaces/email-queue.interface";
import { getStatusClassName, fileToBase64, formatCurrency, formatZip, convertUtcToEst, convertAndFormatDate, adjustDateSystemTimezoneDate, toCssClassName } from "utils/helper";
import { PaymentMethodOption, PdfTypeList, StateCode } from "utils/constants";
import vm from "utils/validationMessages";
import EmailQueueService from "services/email-queue.service";
import HelperViewPdfService from "services/helperViewPdfService";
import EmailQueueDetails from "./EmailQueueDetails";
import dollarImage from "assets/images/dollar-sign.svg";
import Search_Filter from "./EmailQueueSearchFilter";
import CommonValidations from "utils/common-validations";

const EmailQueueGrid = () => {
   const {
      showSpinner,
      getEmailQueues,
      emailQueues,
      setEmailQueues,
      selectedEmailQueueIds,
      setSelectedEmailQueueIds,
      setShowSpinner,
      getAllCompanies,
      getServers,
      getAllCounties,
      allCounties,
      filteredRecords,
      setBulkRecords
   } = useEmailQueueContext();

   const validationSchema = yup.object({
      processServerEmail: yup.string().email(vm.email.email),
      // filerEmail: yup.string().email(vm.email.email).required(vm.email.required),
      filerEmail: yup
    .string()
    .required("Please enter filer email.")
    .test("valid-emails", "Invalid email format. Enter in johndoe@gmail.com, sarahjane@yahoo.com, etc format", (value) => {
      if (!value) return true; // Allow empty value
      const emails = value.split(",").map((email) => email.trim());
      const isValid = emails.every((email) =>
        yup.string().email().isValidSync(email)
      );
      return isValid;
    }),
      // caseNo: yup
      //    .string()
      //    .required(vm.caseNo.required),
      tenantAddress: yup
      .string()
      .required("Please enter address")
      .min(3, "Address must be at least 3 characters")
      .max(300, "Address must not exceed 300 characters"),
      tenantUnit: yup.string(),
      tenantCity: yup
         .string()
         .required("Please enter city")
         .max(50, "City must not exceed 50 characters"),
      tenantState: yup
         .string()
         .required("Please enter state code.")
         .max(2, "State Code must be of 2 characters."),
      propertyName: yup
         .string()
         .required("Please enter property")
         .max(100, "Property must not exceed 100 characters"),
      evictionReason: yup
         .string()
         .required("Please enter reason")
         .max(200, "Reason must not exceed 50 characters"),
      tenant1FirstName: yup
         .string()
         .required(vm.tenant1First.required)
         .max(50, vm.tenant1First.max),
      tenant1MiddleName: yup
         .string()
         .max(50, vm.tenant1MI.max),
      tenant1LastName: yup
         .string()
         .required(vm.tenant1Last.required)
         .max(50, vm.tenant1Last.max),
      expedited: yup.string(),
      county: CommonValidations.countyValidation(allCounties.map(c => c.countyName.toLowerCase())),
      // tenantZip: yup
      //    .string()
      //    .required(vm.zip.required)
      //    .min(5, vm.zip.min)
      //    .max(5, vm.zip.max),
      tenantZip: yup
         .string()
         .required(vm.zip.required),
      referenceId: yup.string()
         .matches(/^[0-9]+$/, vm.referenceId.matches),
      evictionCourtFee: yup
         .string()
         .test(
            "isCurrency",
            vm.evictionCourtFee.test,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const regex = /^\$?\d{1,}(,\d{3})*(\.\d{1,2})?$/;
               return regex.test(value);
            }
         )
         .test(
            "maxAmount",
            vm.evictionCourtFee.testAmount,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
               return numericValue <= 9999;
            }
         ),
      eFileFeeClient: yup
         .string()
         .test(
            "isCurrency",
            vm.eFileFeeClient.test,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const regex = /^\$?\d{1,}(,\d{3})*(\.\d{1,2})?$/;
               return regex.test(value);
            }
         )
         .test(
            "maxAmount",
            vm.eFileFeeClient.testAmount,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
               return numericValue <= 9999;
            }
         ),
      transactionfee: yup
         .string()
         .test(
            "isCurrency",
            vm.transactionfee.test,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const regex = /^\$?\d{1,}(,\d{3})*(\.\d{1,2})?$/;
               return regex.test(value);
            }
         )
         .test(
            "maxAmount",
            vm.transactionfee.testAmount,
            (value) => {
               if (!value) return true; // Skip if undefined or empty
               const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
               return numericValue <= 9999;
            }
         ),
   });

   const initialColumnMapping: IGridHeader[] = [
      //    ...(userRole.includes(UserRole.C2CAdmin)
      //    ? [{
      //       columnName: "isChecked",
      //       // label: "isChecked",
      //       controlType: "checkbox",
      //       label: "Bulk Edit",
      //       toolTipInfo: "This checkbox represents bulk update only"
      //    }]
      //    : []
      // ),
      { columnName: "isChecked", label: "Bulk Edit", controlType: "checkbox", toolTipInfo: "This checkbox represents bulk update only" },
      { columnName: "action", label: "Action", className: "action" },
      { columnName: "status", label: "Status" },
      { columnName: "caseCreatedDate", label: "CaseCreatedDate" },
      { columnName: "taskStatus", label: "TaskStatus" },
      { columnName: "rejectedReason", label: "RejectedReason" },
      { columnName: "referenceId", label: "CaseReferenceId", className: 'text-right' },
      { columnName: "caseNo", label: "CaseNo" },
      { columnName: "documents", label: "PDF link" },
      { columnName: "county", label: "County" },
      { columnName: "processServerEmail", label: "ProcessServerEmail" },
      { columnName: "stateCourt", label: "StateCourt" },
      { columnName: "expedited", label: "Expedited" },
      { columnName: "tenantZip", label: "TenantZip", className: 'text-right' },
      { columnName: "companyName", label: "CompanyName" },
      { columnName: "propertyName", label: "PropertyName" },
      { columnName: "description", label: "Description" },
      { columnName: "tenantOne", label: "TenantOne" },
      { columnName: "tenantAddressCombined", label: "TenantAddressCombined" },
      { columnName: "eFileFeeClient", label: "EfileFee", className: 'text-right' },
      { columnName: "evictionCourtFee", label: "CourtFee", className: 'text-right' },
      { columnName: "transactionfee", label: "CourtTransAmt", className: 'text-right' },
      { columnName: "evictionEnvelopeID", label: "EnvelopeID", className: 'text-right' },
      { columnName: "methodName", label: "eFileMethod" },
      { columnName: "evictionDateFiled", label: "DateFiled" },
      { columnName: "evictionPaymentMethod", label: "EvictionPaymentMethod" },
      { columnName: "paymentAccount", label: "PaymentAccount" },
      { columnName: "attorneyName", label: "AttorneyName" },
      { columnName: "attorneyBarNo", label: "AttorneyBarNo", className: 'text-right' },
      { columnName: "evictionReason", label: "EvictionReason" },
      { columnName: "filerEmail", label: "EvictionFilerEmail" },
      { columnName: "envelopeIdHistory", label: "EvictionEnvelopeIdHistory" },

      // { columnName: "status", label: "Status" },

      // { columnName: "state", label: "State" },
      //  { columnName: "zip", label: "Zip" },

      // { columnName: "methodName", label: "FilingMethod" },
      // { columnName: "transactionfee", label: "EvictionTransactionAmt" },

      // { columnName: "referenceId", label: "ReferenceId" }, 

   ];

   const [openEditModal, setOpenEditModal] = useState<boolean>(false);
   const { userRole } = useAuth();
   const isMounted = useRef(true);
   const [visibleColumns] = useState<IGridHeader[]>(initialColumnMapping);
   const [canPaginateBack, setCanPaginateBack] = useState<boolean>(emailQueues.currentPage > 1);
   const [canPaginateFront, setCanPaginateFront] = useState<boolean>(emailQueues.totalPages > 1);
   const [selectAll, setSelectAll] = useState<boolean>(false)
   const [selectedRows, setSelectedRows] = useState<Array<boolean>>(
      Array(emailQueues.items.length).fill(false)
   );
   const [shiftKeyPressed, setShiftKeyPressed] = useState<boolean>(false);
   const [lastClickedFilteredRowIndex, setLastClickedFilteredRowIndex] = useState<number>(-1);
   const [newRowsSelected] = useState<boolean[]>([]);
   const [selectedData, setSelectedData] = useState<IEditEmailQueueItem | null>(null);
   const [isUpload, setIsUpload] = useState<boolean>(false);
   const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
   const [selectedLinkId, setSelectedLinkId] = useState<string>("");

   const initialValues: IEditEmailQueueItem = {
      id: selectedData?.id ?? "",
      caseNo: selectedData?.caseNo ?? "",
      processServerEmail: selectedData?.processServerEmail ?? "",
      description: selectedData?.description ?? "",
      document: selectedData?.description ?? "",
      expedited: selectedData?.expedited ?? "",
      county: selectedData?.county ?? "",
      evictionTransactionAmt: selectedData?.evictionTransactionAmt ?? "",
      //tenantOne: selectedData?.tenantOne ?? "",
      tenantZip: selectedData?.tenantZip ?? "",
      propertyName: selectedData?.propertyName ?? "",
      referenceId: selectedData?.referenceId ?? "",
      stateCourt: selectedData?.stateCourt ?? "",
      tenant1FirstName: selectedData?.tenant1FirstName ?? "",
      tenant1LastName: selectedData?.tenant1LastName ?? "",
      tenant1MiddleName: selectedData?.tenant1MiddleName ?? "",
      caseCreatedDate: selectedData
      ? new Date(convertAndFormatDate(selectedData.caseCreatedDate)).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "",
      // caseCreatedDate: selectedData ? formattedDate(selectedData.caseCreatedDate as string) : "",
      tenantAddress: selectedData?.tenantAddress ?? "",
      tenantUnit: selectedData?.tenantUnit ?? "",
      eFileFeeClient: selectedData?.eFileFeeClient ?? "",
      evictionCourtFee: selectedData?.evictionCourtFee ?? "",
      evictionEnvelopeID: selectedData?.evictionEnvelopeID ?? "",
      evictionDateFiled: selectedData 
      ? new Date(convertAndFormatDate(selectedData.evictionDateFiled)).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "",
      // evictionDateFiled: selectedData ? formattedDate(selectedData.evictionDateFiled as string) : "",
      evictionPaymentMethod: selectedData?.evictionPaymentMethod ?? "",
      attorneyName: selectedData?.attorneyName ?? "",
      attorneyBarNo: selectedData?.attorneyBarNo ?? "",
      evictionReason: selectedData?.evictionReason ?? "",
      filerEmail: selectedData?.filerEmail ?? "",
      tenantCity: selectedData?.tenantCity ?? "",
      tenantState: selectedData?.tenantState ?? "",
      eFileMethod: selectedData?.eFileMethod ?? "",
      paymentAccount: selectedData?.paymentAccount ?? "",
   };

   const [showDetails, setShowDetails] = useState<boolean>(false);
   const [currentCaseId, setCurrentCaseId] = useState<string>("");

   useEffect(() => {
      if (!filteredRecords.length)
         setSelectAll(false);

   }, [filteredRecords]);

   useEffect(() => {
      if (isMounted.current) {
         getEmailQueues(1, 100, '');
         getAllCounties();
         getAllCompanies();
         getServers();
         setSelectedEmailQueueIds([]);
         isMounted.current = false;
      }

      setSelectedRows(Array(emailQueues.items?.length).fill(false));
      setSelectAll(false);
      setCanPaginateBack(emailQueues.currentPage > 1);
      setCanPaginateFront(emailQueues.totalPages > 1);

      const handleKeyDown = (e: KeyboardEvent) => {
         if (e.key === "Shift") {
            setShiftKeyPressed(true);
         }
      };

      const handleKeyUp = (e: KeyboardEvent) => {
         if (e.key === "Shift") {
            // Reset selected rows to the top (index 0)
            setShiftKeyPressed(false);
         }
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
         window.removeEventListener("keydown", handleKeyDown);
         window.removeEventListener("keyup", handleKeyUp);
      };
      
   }, [getEmailQueues, userRole]);

   const handleFrontButton = () => {
      if (emailQueues.currentPage < emailQueues.totalPages) {
         const updatedCurrentPage = emailQueues.currentPage + 1;
         // Update current page and enable/disable 'Back' button
         setCanPaginateBack(updatedCurrentPage > 1);
         getEmailQueues(updatedCurrentPage, 100, emailQueues.searchParam, emailQueues.companyId, emailQueues.county, emailQueues.serverId, emailQueues.isExpedited, emailQueues.isStateCourt,emailQueues.status,emailQueues.taskStatus);
         // getEmailQueues(updatedCurrentPage, 100, emailQueues.searchParam);
      }
   };

   // const handleSelectAllChange = (checked: boolean) => {
   //    const newSelectAll = !selectAll;
   //    const allIds: string[] = emailQueues.items
   //       .map((item) => item.id)
   //       .filter((id): id is string => typeof id === "string");
   //    if (checked) {
   //       emailQueues.items
   //          .map((item) => setBulkRecords((prev) => [...prev, item]));
   //       setSelectedEmailQueueIds(prevIds => [...new Set([...prevIds, ...allIds])]);
   //    } else {
   //       emailQueues.items.forEach((item) => {
   //          setBulkRecords(prevItems => prevItems.filter(record => record.id !== item.id));
   //          setSelectedEmailQueueIds(prevIds => prevIds.filter(id => id !== item.id));
   //       });
   //    }

   //    setSelectAll((prevSelectAll) => {
   //       // Update selectedRows state
   //       setSelectedRows(Array(allIds.length).fill(newSelectAll));
   //       return newSelectAll;
   //    });
   // };

   const handleSelectAllChange = (checked: boolean) => {
      const allIds: string[] = emailQueues.items
         .map((item) => item.id)
         .filter((id): id is string => typeof id === "string");

      if (checked) {
         // Update bulkRecords with unique items
         setBulkRecords(prev => {
            const existingIds = new Set(prev.map(item => item.id));
            const newItems = emailQueues.items.filter(item => !existingIds.has(item.id));
            return [...prev, ...newItems];
         });

         // Update selectedEmailQueueIds with unique ids
         setSelectedEmailQueueIds(prevIds => {
            const updatedIds = new Set([...prevIds, ...allIds]);
            return Array.from(updatedIds);
         });
      } else {
         // Remove items based on id from bulkRecords
         setBulkRecords(prevItems => prevItems.filter(record => !emailQueues.items.some(item => item.id === record.id)));

         // Remove ids based on id from selectedEmailQueueIds
         setSelectedEmailQueueIds(prevIds => prevIds.filter(id => !allIds.includes(id)));
      }

      // Update selectAll state and selectedRows
      setSelectAll(checked);
      setSelectedRows(Array(allIds.length).fill(checked));
   };

   const handleCheckBoxChange = (index: number, id: string, checked: boolean) => {
      if (shiftKeyPressed && lastClickedFilteredRowIndex !== -1 && emailQueues.items) {
         const start = Math.min(index, lastClickedFilteredRowIndex);
         const end = Math.max(index, lastClickedFilteredRowIndex);
         setSelectedRows(Array.from({ length: selectedRows.length }, (_, i) =>
            i >= start && i <= end ? selectedRows[i] = true : newRowsSelected[i]
         ));
         setSelectedRows(selectedRows);
         const selectedIds = (emailQueues.items || [])
            .filter((_, rowIndex) => selectedRows[rowIndex])
            .map((item) => item.id)
            .filter((id): id is string => typeof id === "string");

         emailQueues.items.filter((_, rowIndex) => selectedRows[rowIndex]).map((item) => {
            setBulkRecords(prevItems => {
               const uniqueItems = new Set(prevItems.map(item => JSON.stringify(item)));
               uniqueItems.add(JSON.stringify(item)); // Add the new item
               return Array.from(uniqueItems).map(item => JSON.parse(item)); // Convert Set back to array
            });
            //  setBulkRecords((prev)=>[...prev,item]);
         })
         setSelectedEmailQueueIds(prevIds => [...new Set([...prevIds, ...selectedIds])]);
      } else {
         const updatedSelectedRows = [...selectedRows];
         updatedSelectedRows[index] = checked;
         setSelectedRows(updatedSelectedRows);

         if (emailQueues.items.length === updatedSelectedRows.filter(item => item).length) {
            setSelectAll(true);
         } else {
            setSelectAll(false);
         }

         var selectedIds = emailQueues.items.filter(item => item.id == id).map((item) => item.id)
            .filter((id): id is string => typeof id === "string");

         if (!checked) {
            // Remove the item from filteredRecords if unchecked        
            setBulkRecords(prevItems => prevItems.filter(item => item.id !== id));
            setSelectedEmailQueueIds(prevIds => prevIds.filter(item => item !== id));
         } else {

            setBulkRecords(prevItems => {
               const uniqueItems = new Set(prevItems.map(item => JSON.stringify(item)));
               uniqueItems.add(JSON.stringify(emailQueues.items.filter(x => x.id === id)[0])); // Add the new item
               return Array.from(uniqueItems).map(item => JSON.parse(item)); // Convert Set back to array
            });
            //setBulkRecords((prev)=>[...prev,allCasesRecords.filter(x=>x.id===id)[0]]);
            // if (selectedItem)
            //   settingData(selectedItem);
            setSelectedEmailQueueIds(prevIds => [...new Set([...prevIds, ...selectedIds])]);
         }

      }
      setLastClickedFilteredRowIndex(index);
   };

   const handleBackButton = () => {
      if (
         emailQueues.currentPage > 1 &&
         emailQueues.currentPage <= emailQueues.totalPages
      ) {
         const updatedCurrentPage = emailQueues.currentPage - 1;
         // Update current page and enable/disable 'Back' button
         setCanPaginateBack(emailQueues.currentPage > 1);
         getEmailQueues(updatedCurrentPage, 100, emailQueues.searchParam, emailQueues.companyId, emailQueues.county, emailQueues.serverId, emailQueues.isExpedited, emailQueues.isStateCourt,emailQueues.status,emailQueues.taskStatus);
         // getEmailQueues(updatedCurrentPage, 100, emailQueues.searchParam);
      }
   };

   const handleShowDetails = (id: string) => {
      setCurrentCaseId(id);
      setShowDetails(true);
   };

   const handleDeletelink = (id: string) => {
      setDeleteConfirmation(true);
      setSelectedLinkId(id);
   };
   const handleDeleted = async () => {
      setDeleteConfirmation(false);
      setShowSpinner(true);
      const response = await EmailQueueService.deletePdfLink(selectedLinkId);
      if (response.status === HttpStatusCode.Ok) {
         if (response.data.isSuccess)
            toast.success(response.data.message);
         else {
            // setErrorMsg(response.data.message);
         }
         setShowSpinner(false);
         getEmailQueues(emailQueues.currentPage, emailQueues.pageSize, emailQueues.searchParam, emailQueues.companyId, emailQueues.county, emailQueues.serverId, emailQueues.isExpedited, emailQueues.isStateCourt);
         // getEmailQueues(emailQueues.currentPage, emailQueues.pageSize, emailQueues.searchParam);
      }
      else
      {
         setShowSpinner(false);
      }
      setSelectedLinkId("");
   };

   const openPdf = async (url: string) => {
      HelperViewPdfService.GetPdfView(url);
   };
   const renderStatusCell = (status: string, county: string) => {
      let colorClass = "";

      switch (status) {
         case "To be processed":
            colorClass = "bg-gray-700 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Submitting":
            colorClass = "bg-yellow-500 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Submitted":
            colorClass = "bg-green-400 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Tyler Error":
            colorClass = "bg-[#fd4545] w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "System Error":
            colorClass = "bg-[#fd4545] w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Rejected":
            colorClass = "bg-[#E61818] w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Accepted":
            colorClass = "bg-blue-400 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Cancelled":
            colorClass = "bg-orange-400 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Cancellation Error":
            colorClass = "bg-[#fd4545] w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "API Off":
            colorClass = "bg-[#A9A9A9] w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         case "Receipted":
            colorClass = "bg-blue-400 w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";
            break;
         default:
            colorClass = "bg-[#F8F8F8] w-32 min-h-6 leading-[13px] text-center py-1 px-2 text-black inline-flex items-center justify-center rounded-sm";
      }

      return <div className={colorClass}>{county.toUpperCase() === "COBB" && status === "Tyler Error" ? "Error" : status}</div>;
   };

   const [hoveredDocumentId, setHoveredDocumentId] = useState<string | null>(null);

   const handleCellRendered = (cellIndex: number, data: IEmailQueueItem, rowIndex: number) => {
      const columnName = visibleColumns[cellIndex]?.label;
      const propertyName = visibleColumns[cellIndex]?.columnName;
      const cellValue = (data as any)[propertyName];
      const renderers: Record<string, () => JSX.Element> = {
         // isChecked: () => (data.caseNo != null && data.caseNo != "") ? (
         //    <GridCheckbox
         //       checked={selectedEmailQueueIds.includes(data.id as string)}
         //       onChange={(checked: boolean) =>
         //          handleCheckBoxChange(rowIndex, data.id, checked)}
         //       label=""
         //    />
         // ) : (
         //    <GridCheckbox
         //       disabled={true}
         //       checked={false}
         //       onChange={(checked: boolean) => { }}
         //       label=""
         //    />
         // ),
         isChecked: () => (
            <GridCheckbox
               checked={selectedEmailQueueIds.includes(data.id as string)}
               onChange={(checked: boolean) =>
                  handleCheckBoxChange(rowIndex, data.id, checked)}
               label=""
            />
         ),
         documents: () => {
            
            const handleCopyClick = (url: string | undefined) => {
               if (url) {
                  navigator.clipboard.writeText(url);
                  toast.success("Document URL copied to clipboard!");
               }
            };

            if (!cellValue || !cellValue.url || !data?.caseNo) {
               return <></> as JSX.Element; // Return an empty fragment instead of null
            }

            return (
               <>
               <div
                  className="relative w-40"
                  onMouseEnter={() => setHoveredDocumentId(data.id)}
                  onMouseLeave={() => setHoveredDocumentId(null)}
               >
               <div className="inline-flex items-center relative pr-3">
               {/* <IconButton
            type={"button"}
            className="cursor-pointer text-[#2472db] absolute right-0 -top-2"
            icon={<FaTimes />}
            handleClick={() => handleDeletelink(cellValue.id ?? "")}
         /> */}
               <h1
                     key={cellValue.id}
                     onClick={() => openPdf(cellValue.url)}
                     className="underline text-[#2472db] mr-1"
                     style={{ cursor: 'pointer' }}
                  >
                     {cellValue.fileName?.endsWith('.pdf') 
      ? cellValue.fileName 
      : `${cellValue.fileName}.pdf`}
                  </h1>
                  {hoveredDocumentId === data.id && (
                     <>
                      <IconButton
            type={"button"}
            className="cursor-pointer text-[#2472db] absolute right-0 -top-2"
            icon={<FaTimes />}
            handleClick={() => handleDeletelink(cellValue.id ?? "")}
         />
                     <FaCopy
                        className="w-4 h-4 text-gray-500 cursor-pointer"
                        onClick={() => handleCopyClick(cellValue.url)}
                     />
                     </>
                  )}
               </div>
                  
               </div>
               </>
            );
            // const openDocuments = async () => {
            //   const aosDocuments = cellValue.filter((item: ICaseDocument) => item.type === 'AOS');
            //   for (const document of aosDocuments) {
            //     await openPdf(document.url);
            //   }
            // };

            // let aosLinkRendered = false;

            // return (
            //    <>
            //       <h1
            //          key={cellValue?.id}
            //          onClick={() => openPdf(cellValue?.url)}
            //          className="underline text-[#2472db]"
            //          style={{ cursor: 'pointer' }}
            //       >
            //          {cellValue?.type == "EvictionStamped" && data?.caseNo != null ? data?.caseNo + ".pdf" : ""}
            //       </h1>

            //    </>
            // );
         },

         tenantOne: () => <HighlightedText text={`${data.tenant1FirstName ?? ''} ${data?.tenant1MiddleName ?? ""} ${data?.tenant1LastName ?? ''}`} query={emailQueues.searchParam ?? ''} />,
         action: () => renderActionCell(data),
         status: () => <div className={getStatusClassName(cellValue)}>{cellValue}</div>,
         taskStatus:()=>renderStatusCell(cellValue,data.county),
         expedited: () => <span>{cellValue != "" && cellValue != null ? "Expedited" : ""}</span>,
         propertyName: () => <HighlightedText text={data.propertyName ?? ""} query={emailQueues.searchParam ?? ''} />,
         tenantZip: () => <HighlightedText text={data.tenantZip ?? ""} query={emailQueues.searchParam ?? ''} />,
         caseNo: () => <HighlightedText text={data.caseNo ?? ""} query={emailQueues.searchParam ?? ''} />,
         county: () => <HighlightedText text={data.county ?? ""} query={emailQueues.searchParam ?? ''} />,
         processServerEmail: () => <HighlightedText text={data.processServerEmail ?? ""} query={emailQueues.searchParam ?? ''} />,
         filerEmail: () => <HighlightedText text={data.filerEmail ?? ""} query={emailQueues.searchParam ?? ''} />,
         attorneyName: () => <HighlightedText text={data.attorneyName ?? ""} query={emailQueues.searchParam ?? ''} />,
         attorneyBarNo: () => <HighlightedText text={data.attorneyBarNo ?? ""} query={emailQueues.searchParam ?? ''} />,
         // referenceId: () => <HighlightedText text={data.referenceId ?? ""} query={emailQueues.searchParam ?? ''} />,
         caseCreatedDate: () => formattedDateCell(cellValue),
         tenantAddressCombined: () => <HighlightedText text={`${data.tenantAddress ?? ''} ${data.tenantUnit ?? ''} ${data.tenantCity ?? ''} ${data.tenantState ?? ''} ${data.tenantZip ?? ''}`} query={emailQueues.searchParam ?? ''} />,
         evictionDateFiled: () => formattedDateCell(cellValue),
         transactionfee: () => renderHighlightedAmount(cellValue, data),
         // transactionfee: () => cellValue == ""? renderHighlightedAmount(null, data):renderHighlightedAmount(cellValue, data),
         eFileFeeClient: () => {
            let formattedValue = cellValue;

            // Try to parse the cellValue as a number
            const numericValue = parseFloat(cellValue);

            // Check if the parsed value is a valid number
            if (!isNaN(numericValue)) {
               // Format as currency if it's a valid number
               formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue);
            }

            return <span>{formattedValue}</span>;
         },
         evictionCourtFee: () => {
            let formattedValue = cellValue;

            // Try to parse the cellValue as a number
            const numericValue = parseFloat(cellValue);

            // Check if the parsed value is a valid number
            if (!isNaN(numericValue)) {
               // Format as currency if it's a valid number
               formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue);
            }

            return <span>{formattedValue}</span>;
         },
      };

      const renderHighlightedAmount = (cellValue: any, data: IEmailQueueItem) => {
         if (data && data.isTransactionFeeChanged) {
            return <span style={{ backgroundColor: 'lightgreen' }}>{formatCurrencyCell(cellValue) ?? ""}</span>;
         } else {
            return <span>{formatCurrencyCell(cellValue) ?? ""}</span>;
         }
      };

      const renderer = renderers[propertyName] || (() => formattedCell(cellValue));

      if (visibleColumns.find(x => x.label === columnName)) {
         return (
            <td
               key={cellIndex}
               className={`px-1.5 py-2 md:py-2.5 font-normal text-[10.3px] md:text-[11px] text-[#2a2929]  ${toCssClassName(columnName)}`}
            >
               {renderer()}
            </td>
         );
      }

      return <></>;
   };

   const formatCurrencyCell = (value: number) => (
      <span>{value !== null ? formatCurrency(value) : ""}</span>
   );

   const formattedDateCell = (value: any) => (
      <span>{value !== null ? convertUtcToEst(value).date : ""}</span>
   );

   const formattedCell = (value: any) => (
      <span>{value !== null ? value : ""}</span>
   );

   const checkIfAllIdsExist = (
      emailQueueUsers: IEmailQueueItem[],
      selectedEmailQueueIds: string[]
   ): boolean | undefined => {
      if (emailQueueUsers.length === 0) {
         return false;
      }
      return emailQueueUsers.every(record =>
         selectedEmailQueueIds.includes(record.id as string)
      );
   };

   // const checkIfAllIdsExist = (
   //    emailQueueUsers: IEmailQueueItem[],
   //    selectedEmailQueueIds: string[]
   // ): boolean | undefined => {
   //    return emailQueueUsers.every(record =>
   //       selectedEmailQueueIds.includes(record.id as string)
   //    );
   // };

   // const handleEditData = async (formValues: IEditEmailQueueItem) => {
   //    
   //    // formValues.id=selectedData?.id??"";
   //    const fileBase64 = await fileToBase64(formValues.get("file"));

   //    setOpenEditModal(false);
   //    setShowSpinner(true);
   //    const response = await EmailQueueService.UpdateCaseInfo(formValues);
   //    if (response.status === HttpStatusCode.Ok) {
   //       toast.success("Updated Successfully.");
   //       getEmailQueues(1, 100);

   //    }
   //    setShowSpinner(false);
   // }

   const handleEditData = async (formValues: IEditEmailQueueItem) => {
      try {
         
         setOpenEditModal(false);
         setShowSpinner(true);

         // Get the file input element by ID or other selector
         const fileInput = document.getElementById("fileUpload") as HTMLInputElement | null;

         if (fileInput && fileInput.files && fileInput.files.length > 0) {
            // Access the first file from the file input element
            const file = fileInput.files[0];

            // Convert the file to Base64
            const fileBase64 = await fileToBase64(file);

            // Set the Base64 value in the form values
            formValues.document = fileBase64;

            // Call the API with the updated form values

         }
         // const caseCreateDate = new Date(formValues.caseCreatedDate);
         // const dateFiled =formValues.evictionDateFiled!=null? new Date(formValues.evictionDateFiled):null;


         // Adjust to local timezone offset
         // formValues.caseCreatedDate = new Date(caseCreateDate.getTime() - (caseCreateDate.getTimezoneOffset() * 60000));
         // formValues.evictionDateFiled =dateFiled!=null? new Date(dateFiled.getTime() - (dateFiled.getTimezoneOffset() * 60000)):null;
         formValues.caseCreatedDate= adjustDateSystemTimezoneDate(
            typeof formValues.caseCreatedDate === 'string' ? 
            formValues.caseCreatedDate : 
            formValues.caseCreatedDate.toISOString()
          ) ?? formValues.caseCreatedDate;
          formValues.evictionDateFiled = formValues.evictionDateFiled != null? adjustDateSystemTimezoneDate(
            typeof formValues.evictionDateFiled === 'string' ? 
            formValues.evictionDateFiled  : 
            formValues.evictionDateFiled.toISOString()
          ) 
          : null; 
         formValues.evictionEnvelopeID = formValues.evictionEnvelopeID.toString();
         formValues.caseNo = formValues.caseNo == null ? "" : formValues.caseNo;
         formValues.eFileMethod = formValues.eFileMethod ?? "";
         formValues.evictionPaymentMethod = formValues.evictionPaymentMethod ?? "";
         formValues.evictionTransactionAmt = formValues.evictionTransactionAmt ? formValues.evictionTransactionAmt.toString() : "";
         formValues.evictionCourtFee = formValues.evictionCourtFee ? formValues.evictionCourtFee.toString() : "";
         formValues.eFileFeeClient = formValues.eFileFeeClient ? formValues.eFileFeeClient.toString() : "";
         formValues.tenantZip = formValues.tenantZip != null ? formatZip(formValues.tenantZip) : "";
         // Call the API with the updated form values
         const response = await EmailQueueService.UpdateCaseInfo(formValues);

         if (response.status === HttpStatusCode.Ok) {
            toast.success("Updated Successfully.");
         setShowSpinner(false);

            // getEmailQueues(1, 100, '');
            getEmailQueues(emailQueues.currentPage,emailQueues.pageSize, emailQueues.searchParam, emailQueues.companyId, emailQueues.county, emailQueues.serverId, emailQueues.isExpedited, emailQueues.isStateCourt,emailQueues.status,emailQueues.taskStatus);
            // getEmailQueues(emailQueues.currentPage,emailQueues.pageSize, emailQueues.searchParam,emailQueues.companyId ,emailQueues.county ,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt);

         }
      } catch (error) {
         console.error("Error:", error);
      } finally {
         // setShowSpinner(false);
      }
   };

   const renderActionCell = (data: IEmailQueueItem) => (
      <div className="flex justify-center gap-2">
         <IconButton
            type={"button"}
            className="cursor-pointer text-[#2472db]"
            disabled={!data?.id?.length}
            icon={<FaEye className="h-4 w-4" />}
            handleClick={() => handleShowDetails(data.id ?? "")}
         />
         <IconButton
            type={"button"}
            className="cursor-pointer text-[#2472db]"
            disabled={!data?.id?.length}
            icon={<FaEdit className="h-[14px] w-[14px]" />}
            handleClick={() => {

               setSelectedData({
                  id: data.id, caseNo: data?.caseNo ?? "",
                  processServerEmail: data?.processServerEmail ?? "",
                  description: data?.description ?? "",
                  document: "",
                  expedited: data?.expedited ?? "",
                  county: data?.county ?? "",
                  evictionTransactionAmt: data?.transactionfee ?? "",
                  //tenantOne:data?.tenantOne ?? "",
                  tenantZip: data?.tenantZip ?? "",
                  propertyName: data?.propertyName ?? "",
                  referenceId: data?.referenceId ?? "",
                  stateCourt: data?.stateCourt ?? "",
                  tenant1FirstName: data?.tenant1FirstName ?? "",
                  tenant1LastName: data?.tenant1LastName ?? "",
                  tenant1MiddleName: data?.tenant1MiddleName ?? "",
                  caseCreatedDate: data?.caseCreatedDate ?? "",
                  tenantAddress: data?.tenantAddress ?? "",
                  tenantUnit: data?.tenantUnit ?? "",
                  eFileFeeClient: data?.eFileFeeClient ?? "",
                  evictionCourtFee: data?.evictionCourtFee ?? "",
                  evictionEnvelopeID: data.evictionEnvelopeID ?? "",
                  evictionDateFiled: data.evictionDateFiled ?? "",
                  evictionPaymentMethod: data?.evictionPaymentMethod ?? "",
                  attorneyName: data?.attorneyName ?? "",
                  attorneyBarNo: data?.attorneyBarNo ?? "",
                  evictionReason: data?.evictionReason ?? "",
                  filerEmail: data.filerEmail ?? "",
                  tenantCity: data.tenantCity ?? "",
                  tenantState: data.tenantState ?? "",
                  eFileMethod: data?.methodName ?? "",
                  paymentAccount: data?.paymentAccount ?? "",
               });
               setOpenEditModal(true);
            }}
         />
      </div>
   );

   return (
      <div className="pt-2.5">
         <div className="relative -mr-0.5">
            <div className="mb-2.5"> <Search_Filter /></div>
            {showSpinner ? <Spinner /> : (
               <>
                  <Grid
                     columnHeading={visibleColumns}
                     rows={emailQueues.items}
                     handleSelectAllChange={handleSelectAllChange}
                     selectAll={checkIfAllIdsExist(emailQueues.items, selectedEmailQueueIds)}
                     cellRenderer={(data: IEmailQueueItem, rowIndex: number, cellIndex: number) => {
                        return handleCellRendered(cellIndex, data, rowIndex);
                     }}
                  // handleSorting={handleSorting}
                  />
                  {/* Render the Pagination component with relevant props */}
                  <Pagination
                     numberOfItemsPerPage={emailQueues.pageSize}
                     currentPage={emailQueues.currentPage}
                     totalPages={emailQueues.totalPages}
                     totalRecords={emailQueues.totalCount}
                     handleFrontButton={handleFrontButton}
                     handleBackButton={handleBackButton}
                     canPaginateBack={canPaginateBack}
                     canPaginateFront={canPaginateFront}
                  />
               </>
            )}
         </div>
         <Modal showModal={openEditModal} onClose={() => setOpenEditModal(false)} width="max-w-4xl">
            <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 rounded-md">
               <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                     <h3
                        className="leading-5 text-gray-900 text-[16px] md:text-xl mb-1.5"
                        id="modal-title"
                     >
                        Update
                     </h3>
                  </div>
               </div>
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleEditData}
               >
                  {({ values, setFieldValue }) => (
                     <Form className="pt-1">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2.5 sm:gap-3.5 mb-2.5">
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Tenant1FirstName"}
                                 name={"tenant1FirstName"}
                                 placeholder={"Enter Tenant1FirstName"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Tenant1MiddleName"}
                                 name={"tenant1MiddleName"}
                                 placeholder={"Enter Tenant1MiddleName"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Tenant1LastName"}
                                 name={"tenant1LastName"}
                                 placeholder={"Enter Tenant1LastName"}
                              />
                           </div>

                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Case Number"}
                                 name={"caseNo"}
                                 placeholder={"Enter Case Number"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Process Server Email"}
                                 name={"processServerEmail"}
                                 placeholder={"Enter Process Server Email"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="date"
                                 type="date"
                                 label={"CaseCreatedDate"}
                                 name={"caseCreatedDate"}
                                 placeholder={"Enter CaseCreatedDate"}
                                 minDate={null}
                                 // dateFormat="MM/dd/yyyy"
                                 disabled={true}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Description"}
                                 name={"description"}
                                 placeholder={"Enter Description"}
                              />
                           </div>

                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Expedited"}
                                 name={"expedited"}
                                 placeholder={"Enter Expedited"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"County"}
                                 name={"county"}
                                 placeholder={"Enter County"}
                              />
                           </div>

                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EFileMethod"}
                                 name={"eFileMethod"}
                                 placeholder={"Enter eFileMethod"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionPaymentMethod"}
                                 name={"evictionPaymentMethod"}
                                 placeholder={"Enter EvictionPaymentMethod"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="select"
                                 type="select"
                                 label={"PaymentAccount"}
                                 name={"paymentAccount"}
                                 defaultOption={"Please select"}
                                 placeholder={"paymentAccount"}
                                 options={PaymentMethodOption}
                                 selected={initialValues.paymentAccount}
                              />
                           </div>
                           <div className="relative">
                              {/* <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionCourtTransAmt"}
                                 name={"evictionTransactionAmt"}
                                 placeholder={"Enter EvictionCourtTransAmt"}
                              /> */}
                              <FormikControl
                                 control="input"
                                 type="number"
                                 label={"CourtTransAmt"}
                                 name={"evictionTransactionAmt"}
                                 placeholder={"Enter EvictionCourtTransAmt"}
                                 style={{
                                    backgroundImage: `url(${dollarImage})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '10px center', // Adjust the position as needed
                                    backgroundSize: '9px', // Adjust size to fit the image nicely
                                    paddingLeft: '28px', // Increase padding to ensure space between the text and image
                                 }}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"TenantZip"}
                                 name={"tenantZip"}
                                 // maxLength={5}
                                 placeholder={"Enter TenantZip"}
                              />
                           </div>

                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"TenantAddress"}
                                 name={"tenantAddress"}
                                 placeholder={"Enter TenantAddress"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"TenantCity"}
                                 name={"tenantCity"}
                                 placeholder={"Enter TenantCity"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"TenantUnit"}
                                 name={"tenantUnit"}
                                 placeholder={"Enter TenantUnit"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="select"
                                 type="select"
                                 label={"Tenant State"}
                                 name={"tenantState"}
                                 defaultOption={"Please select"}
                                 placeholder={"State"}
                                 options={StateCode}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"PropertyName"}
                                 name={"propertyName"}
                                 placeholder={"Enter PropertyName"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"CaseReferenceID"}
                                 name={"referenceId"}
                                 placeholder={"Enter Case Reference ID"}
                                 disabled={true}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="number"
                                 label={"EfileFee"}
                                 name={"eFileFeeClient"}
                                 placeholder={"Enter EvictionEfileFee"}
                                 style={{
                                    backgroundImage: `url(${dollarImage})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '10px center',
                                    backgroundSize: '9px',
                                    paddingLeft: '28px',
                                 }}
                              />
                           </div>
                           <div className="relative">
                              {/* <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionCourtFee"}
                                 name={"evictionCourtFee"}
                                 placeholder={"Enter EvictionCourtFee"}
                              /> */}
                              <FormikControl
                                 control="input"
                                 type="number"
                                 label={"CourtFee"}
                                 name={"evictionCourtFee"}
                                 placeholder={"Enter EvictionCourtFee"}
                                 style={{
                                    backgroundImage: `url(${dollarImage})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: '10px center',
                                    backgroundSize: '9px',
                                    paddingLeft: '28px',
                                 }}
                              />
                           </div>
                           {/* <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="number"
                                 label={"EvictionEnvelopeID"}
                                 name={"evictionEnvelopeID"}
                                 placeholder={"Enter EvictionEnvelopeID"}
                              />
                           </div> */}
                           <div className="relative">
                              <FormikControl
                                 control="date"
                                 type="date"
                                 label={"DateFiled"}
                                 name={"evictionDateFiled"}
                                 placeholder={"Enter EvictionDateFiled"}
                                 minDate={null}
                              />
                           </div>
                           {/* <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionPaymentMethod"}
                                 name={"evictionPaymentMethod"}
                                 placeholder={"Enter EvictionPaymentMethod"}
                              />
                           </div> */}
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"AttorneyName"}
                                 name={"attorneyName"}
                                 placeholder={"Enter AttorneyName"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="number"
                                 label={"AttorneyBarNo"}
                                 name={"attorneyBarNo"}
                                 placeholder={"Enter AttorneyBarNo"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionReason"}
                                 name={"evictionReason"}
                                 placeholder={"Enter EvictionReason"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"EvictionFilerEmail"}
                                 name={"filerEmail"}
                                 placeholder={"Enter EvictionFilerEmail"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"StateCourt"}
                                 name={"stateCourt"}
                                 placeholder={"Enter StateCourt"}
                              />
                           </div>
                           {/* <div className="relative">
                              <FormikControl
                                 control="select"
                                 type="select"
                                 label={"Attachment Type"}
                                 name={"attachmentType"}
                                 defaultOption={"Please select"}
                                 placeholder={"Type"}
                                 options={AttachmentType}
                              />
                           </div> */}
                           <div className="relative">
                              <div>
                                 <label htmlFor="fileUpload" className="text-gray-600 text-[11px] md:text-xs font-medium">
                                    Upload PDF Document
                                 </label>
                                 {/* <span className="ml-1">
                                 <Tooltip
                                    id={"document_info"}
                                    content={"Please select an attachment type first to upload PDF."}
                                    children={<FaInfoCircle className="text-blue-600" />}
                                 />
                              </span> */}
                              </div>
                              <input
                                 id="fileUpload"
                                 name="file"
                                 type="file"
                                 accept=".pdf"
                                 className="focus:ring-blue-500 focus:border-blue-500 block w-full border w-full border-gray-200 rounded-md text-xs px-[6px] py-[5px] md:px-[8px] md:py-[7px]"
                                 onChange={(event) => {
                                    const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                                    setFieldValue("document", file);
                                 }}
                              />
                              {/* <label className="flex">{values.attachmentType === '' ? <span className="text-[#eb5757] text-[11.5px]">Please select an Attachment Type first.</span> : ''}</label> */}
                           </div>
                        </div>
                        <div className="mt-2 md:mt-0 py-3 pb-1 md:pb-2 flex justify-end items-center">
                           <Button
                              type="button"
                              isRounded={false}
                              title="Cancel"
                              handleClick={() => setOpenEditModal(false)}
                              classes="text-xs bg-white inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-[#f5f8fb] hover:ring-slate-900/15 shadow-lg"
                           ></Button>
                           <Button
                              title={"Update"}
                              type={"submit"}
                              isRounded={false}
                              disabled={showSpinner}
                              classes="py-2 md:py-2.5 px-4 inline-flex justify-center items-center gap-x-1.5 text-xs font-semibold rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none   "
                           ></Button>
                        </div>
                     </Form>
                  )}
               </Formik>
            </div>
         </Modal>

         {showDetails && (
            <EmailQueueDetails
               caseId={currentCaseId}
               showDetails={showDetails}
               setShowDetails={(show: boolean) => setShowDetails(show)}
            />
         )}
         {deleteConfirmation && (
            <div>
               <Modal
                  showModal={deleteConfirmation}
                  onClose={() => setDeleteConfirmation(false)}
                  width="max-w-md"
               >
                  <div id="fullPageContent">
                     <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                        <div className="text-center pr-4 sm:text-left">
                           <h3
                              className="text-sm font-semibold leading-5 text-gray-900"
                              id="modal-title"
                           >
                              Are you sure you want to delete pdf link?
                           </h3>
                        </div>
                     </div>
                     <div className="flex justify-end m-2">
                        <Button
                           type="button"
                           isRounded={false}
                           title="No"
                           handleClick={() => setDeleteConfirmation(false)}
                           classes="text-[11px] md:text-xs bg-white	inline-flex justify-center items-center rounded-md text-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 shadow-lg "
                        ></Button>
                        <Button
                           handleClick={handleDeleted}
                           title="Delete"
                           isRounded={false}
                           type={"button"}
                           classes="text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
                        ></Button>
                     </div>
                  </div>
               </Modal>
            </div>
         )}
      </div>
   );
};

export default EmailQueueGrid;
